<template>
  <div class="page">
    <div class="navbar-expand-md" v-if="user">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu" aria-controls="navbar-menu" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
      <div class="collapse navbar-collapse" id="navbar-menu">
        <div class="navbar navbar-light">
          <div class="container-xl">
            <ul class="navbar-nav" style="width: 100%">
              <router-link
                to="/"
                class="nav-item"
                :class="$route.path === '/' ? 'active' : ''"
              >
                <div class="nav-link">
                  <span class="nav-link-icon d-md-none d-lg-inline-block">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-home"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <polyline
                          points="5 12 3 12 12 3 21 12 19 12"
                        ></polyline>
                        <path
                          d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"
                        ></path>
                        <path
                          d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"
                        ></path>
                      </svg>
                    </div>
                  </span>
                  <span class="nav-link-title"> Home </span>
                </div>
              </router-link>
              <router-link
                to="admin"
                v-if="isSuperAdminRole"
                class="nav-item"
                :class="$route.path === '/admin' ? 'active' : ''"
              >
                <div class="nav-link">
                  <span class="nav-link-icon d-md-none d-lg-inline-block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-shield-lock"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3"
                      ></path>
                      <circle cx="12" cy="11" r="1"></circle>
                      <line x1="12" y1="12" x2="12" y2="14.5"></line>
                    </svg>
                  </span>
                  <span class="nav-link-title"> Admin </span>
                </div>
              </router-link>
              <router-link
                to="/settings"
                class="nav-item"
                :class="$route.path === '/settings' ? 'active' : ''"
              >
                <div class="nav-link">
                  <span class="nav-link-icon d-md-none d-lg-inline-block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-settings"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z"
                      ></path>
                      <circle cx="12" cy="12" r="3"></circle>
                    </svg>
                  </span>
                  <span class="nav-link-title"> Settings </span>
                </div>
              </router-link>
              <!-- <li class="nav-item" v-if="isOrganizations">
                <a class="nav-link" href="/">
                  <span class="nav-link-icon d-md-none d-lg-inline-block">
                  </span>
                  <span class="nav-link-title"> Organizations </span>
                </a>
              </li> -->
              <div class="nav-item dropdown flex-row" style="margin-left: auto">
                <a
                  href="#"
                  class="nav-link d-flex lh-1 text-reset p-0"
                  data-bs-toggle="dropdown"
                  aria-label="Open user menu"
                >
                  <span
                    class="avatar avatar-sm"
                    :style="'background-image: url(' + getUserImage + ')'"
                  >
                  </span>
                  <div class="d-none d-xl-block ps-2">
                    <div v-if="user">{{ user.Email.split("@")[0] }}</div>
                  </div>
                </a>
                <div
                  class="dropdown-menu dropdown-menu-end dropdown-menu-arrow"
                >
                  <router-link to="/settings" class="dropdown-item">Account</router-link>
                  <router-link to="/settings/organizations" class="dropdown-item">Organizations</router-link>
                  <router-link to="/settings/securitylogs" class="dropdown-item">Security Logs</router-link>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" @click="store.dispatch('logout')">Logout</a>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="container-xl">
        <router-view />
      </div>
      <footer class="footer footer-transparent d-print-none">
        <div class="container">
          <div class="row text-center align-items-center flex-row-reverse">
            <div class="col-lg-auto ms-lg-auto">
              <ul class="list-inline list-inline-dots mb-0">
                <a href="#" class="mx-2" @click="toggleTheme">
                  <font-awesome-icon :icon="ResolveThemeIcon" class="link-secondary" />
                </a>
                <a href="https://www.skaarhoj.com/contact-support" class="link-secondary">Support</a>
              </ul>
            </div>
            <div class="col-12 col-lg-auto mt-3 mt-lg-0">
              <ul class="list-inline list-inline-dots mb-0">
                <li class="list-inline-item">
                  Copyright © {{ new Date().getFullYear() }} SKAARHOJ
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { api } from '@/api'
import { refreshToken } from '@/api/authentication'

const store = useStore()
const route = useRoute()

const isSuperAdminRole = computed(() => store.getters.isSuperAdminRole)
const user = computed(() => store.state.user)
const theme = computed(() => store.state.theme)

const getUserImage = computed(() => api.defaults.baseURL + '/user/image')
const ResolveThemeIcon = computed(() => theme.value === 'dark' ? 'fa-moon' : 'fa-sun')

function toggleTheme () {
	store.commit('toggleTheme')
}

function listenSystemThemeChange () {
	window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
		store.commit('setTheme', event.matches ? 'dark' : 'light')
	})
}

onMounted(async () => {
	if (route.path !== '/origin') {
		try {
			const data = await refreshToken()
			if (data.Status === 'ok') {
				store.commit('loginSuccess', data.Data)
				store.state.organizationName = ''
				store.state.createOrganization = false
			}
		} catch {
			// Dont care
		}
	}
	listenSystemThemeChange()
})
</script>

<style>
#app {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a {
  color: rgba(35, 46, 60, 0.7) !important;
  text-decoration: none !important;
}
.page, .content {
	height: 100%;
}
.content {
	overflow-y: auto;
	display: flex;
	flex-direction: column;
}
.footer {
	height: 84px;
}
</style>

<style>
body[data-bs-theme=dark] {
  .swal2-popup {
    background-color: var(--tblr-body-bg);
    color: var(--tblr-body-color);
  }
  ::selection {
    background-color: #5AC8FA;
    color: black;
  }
}
body.override.swal2-height-auto {
  height: 100% !important;
}
</style>
