import { api } from '.'

export async function signin (user) {
	const response = await api.post(
		'/signin',
		{
			email: user.email,
			password: user.password,
			recaptcha: user.recaptcha,
			code: user.code
		}
	)
	return response.data
}
export async function logout () {
	const response = await api.post(
		'/logout'
	)
	return response.data
}

export async function signup (user) {
	const response = await api.post(
		'/signup',
		{
			email: user.email,
			password: user.password,
			recaptcha: user.recaptcha
		}
	)
	return response.data
}

export async function resendSignupEmail () {
  const response = await api.post('/resend-signup-email')
  return response.data
}

export async function sendPasswordRecoveryCode (email) {
	const response = await api.post(
		'/sendpasswordrecovery', email
	)
	return response.data
}

export async function passwordRecovery (data) {
	const response = await api.post(
		'/passwordrecovery', data
	)
	return response.data
}

export async function emailVerification (code) {
	const response = await api.post(
		'/verify', { code: code }
	)
	return response.data
}

export async function refreshToken () {
	const response = await api.post(
		'/refreshtoken'
	)
	return response.data
}
