<template>
  <div class="container-tight">
    <div style="
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
      ">
      <div style="display: flex;  justify-content: center; width: 100%">
        <img src="/logo.png" height="25" class="mb-4" />
      </div>
      <div class="card card-md">
        <div class="card-body">
          <h2 class="card-title text-center mb-4">Enter Verification code that you have received by Email</h2>
          <div class="mb-3">
            <label class="form-label" style="text-align: left;">Verification Code</label>
            <input v-model="code" type="text" class="form-control" placeholder="Verification Code" autocomplete="off" />
          </div>
          <div class="form-footer d-flex">
            <button class="btn btn-outlined" :disabled="count > 0" @click="resendEmail">Resend email {{ countdown }}</button>
            <button
              type="submit"
              class="btn btn-primary ms-auto"
              :class="{ 'btn-loading': loading }"
              @click="initEmailVerification()"
            >
              Verify
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, getCurrentInstance, onBeforeUnmount, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import { resendSignupEmail } from '../api/authentication'

const $vue = getCurrentInstance()
const router = useRouter()
const store = useStore()

const code = ref('')
const count = ref(60)
const loading = ref(false)

const countdown = computed(() => {
  if (count.value === 0) return ''

  return `(${count.value}s)`
})

function initEmailVerification () {
  if (!code.value) return
  loading.value = true

  store.dispatch('emailVerification', code.value)
    .then(() => {
      router.push('/signin')
      loading.value = false
    })
    .catch((err) => {
      $vue?.proxy.$swal.fire({
        icon: 'error',
        text: err.response.data.Data
      })
      loading.value = false
    })
}

function resendEmail () {
  resendSignupEmail()
    .then(startCountDown)
    .catch(err => $vue?.proxy.$swal.fire({
      icon: 'error',
      text: err.response.data.Data
    }))
}

let interval
function startCountDown () {
  count.value = 60
  interval = setInterval(() => {
    count.value -= 1
    if (count.value < 1) { clearInterval(interval) }
  }, 1000)
}

onMounted(() => {
  startCountDown()
})

onBeforeUnmount(() => {
  clearInterval(interval)
})
</script>
